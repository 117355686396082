import * as React from "react";
import Logo1 from "../../assets/appImages/logo1.png";
import Logo2 from "../../assets/appImages/logo2.png";
import Logo3 from "../../assets/appImages/logo3.png";
import Logo4 from "../../assets/appImages/logo4.png";
import Logo5 from "../../assets/appImages/logo5.png";
import Logo6 from "../../assets/appImages/logo6.png";
import Logo7 from "../../assets/appImages/logo7.png";
import Logo8 from "../../assets/appImages/logo8.png";
import Logo9 from "../../assets/appImages/logo9.png";
import Logo10 from "../../assets/appImages/logo10.png";
import Logo11 from "../../assets/appImages/logo11.png";
import Logo12 from "../../assets/appImages/logo12.png";
import Logo13 from "../../assets/appImages/logo13.png";
import Logo14 from "../../assets/appImages/logo14.png";
import Logo15 from "../../assets/appImages/logo15.png";
import Logo16 from "../../assets/appImages/logo16.png";
import Logo18 from "../../assets/appImages/logo18.png";
import Logo19 from "../../assets/appImages/logo19.png";
import Logo20 from "../../assets/appImages/logo20.png";
import Logo21 from "../../assets/appImages/logo21.png";
import Logo17 from "../../assets/appImages/logo17.png";

const Reference = () => {
  return (
    <div className="container-app-pitch-palabre">
      <div className="content-title-section-reference text-center">
        <h2 className="h2-theme my-5">Nos références</h2>
      </div>
      <div className="container-reference mb-3">
        <div className="content-logo-reference-item">
          <img
            src={Logo1}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo2}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo3}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo4}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo5}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo6}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo7}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
      </div>
      <div className="container-reference mb-3">
        <div className="content-logo-reference-item">
          <img
            src={Logo8}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo9}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo10}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo11}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo12}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo13}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo14}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
      </div>
      <div className="container-reference mb-3">
        <div className="content-logo-reference-item">
          <img
            src={Logo15}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo16}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo17}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo18}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo19}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo20}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
        <div className="content-logo-reference-item">
          <img
            src={Logo21}
            alt="logo référence"
            className="img-reference-item"
          />
        </div>
      </div>
    </div>
  );
};

export default Reference;
