import React from "react";
import HomePage from "../components/HomePage/HomePage";

// UserRoutes

export const AppRoutes = [
  {
    path: "/",
    element: <HomePage />,
  },
];
