import React from "react";
import "./FrontFooter.css";
import Logo from "../../../assets/appImages/logo.png";
import { GrLinkedinOption } from "react-icons/gr";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
const FrontFooter = () => {
  return (
    <footer className="footer-component">
      <div className="container-app-pitch-palabre">
        <div className="container-copyright">
          <div className="footer-grid">
            <img src={Logo} alt="logo" className="logo-app-footer" />
          </div>
          <p className="copyright mb-0">
            (c) Copyright PitchPalabre2022. All right reserved.
          </p>
          <div className="Social-media-to-share-article">
            <a href="#" className="facebook">
              <FaFacebookF className="share-icon-social-media-item" />
            </a>
            <a href="#" className="twitter">
              <FaTwitter className="share-icon-social-media-item" />
            </a>
            <a href="#" className="linkedin">
              <GrLinkedinOption className="share-icon-social-media-item" />
            </a>
            <a href="#" className="instagram">
              <AiOutlineInstagram className="share-icon-social-media-item" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FrontFooter;
