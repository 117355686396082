import React from "react";
import ImgBanner from "../../assets/appImages/img-banner.png";

const BannerPage = () => {
  return (
    <div className="banner-page-container">
      <div className="container-app-pitch-palabre">
        <div className="row row-banner">
          <div className="col-md-6 col-left-banner-home-page">
            <div className="content-col-left-home-page">
              <p className="name-logo-app-content">Pitch Palabre</p>
              <h1 className="title-home-page-banner">
                Nous accompagnons les entrepreneur.e.s d’afrique francophone
              </h1>
              <div className="content-text-description-home-page-banner mt-4 mb-5">
                <p className="text-description-home-page-banner">
                  Nous concevons implémentons des programmes d’accompagnement en
                  Afrique francophone.
                </p>
              </div>
              <div className="content-btn-connect-banner pt-4">
                <a className="btn btn-connect-banner" href="#nous-contacter">
                  NOUS CONTACTER
                  <span className="span-button"></span>
                  <span className="span-button"></span>
                  <span className="span-button"></span>
                  <span className="span-button"></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-right-home-page-banner">
            <div className="content-col-right-home-page-banner">
              <div className="container-img-banner">
                <img
                  src={ImgBanner}
                  alt="image bannière"
                  className="img-banner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPage;
