import * as React from "react";
import "./HomePage.css";
import FrontHeader from "./FrontHeader/FrontHeader";
import FrontFooter from "./FrontFooter/FrontFooter";
import BannerPage from "./BannerPage";
import Img1 from "../../assets/appImages/apropos2.png";
import ImgApproche from "../../assets/appImages/approche.png";
import Icon1 from "../../assets/icons/cycle.png";
import Icon2 from "../../assets/icons/design.png";
import Icon3 from "../../assets/icons/search.png";
import Icon4 from "../../assets/icons/help.png";
import Img2 from "../../assets/appImages/apropos1.png";
import IconValeur from "../../assets/icons/valeur.png";
import EquipeImg from "../../assets/appImages/equipe.png";
import Reference from "./Reference";
import { FaLinkedin } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Datas = [
  {
    id: 1,
    title: "Pour les entrepreneurs par des entrepreneurs",
    img: Icon1,
    text: "Nous accompagnons les entrepreneur.e.s d’afrique francophone dans le 0 to 1 pour les aider à mieux vendre, lever leur 1er fonds et trouver l’adéquation produit marché. N",
  },
  {
    id: 2,
    title: "Un mÉthodOlogie éprouvÉE À MOINDRE COûTS",
    img: Icon2,
    text: "Nous accompagnons les entrepreneur.e.s d’afrique francophone dans le 0 to 1 pour les aider à mieux vendre, lever leur 1er fonds et trouver l’adéquation produit marché. N",
  },
  {
    id: 3,
    title:
      "VALORISATION Des résultats de recherches des universités africaineS",
    img: Icon3,
    text: "Nous accompagnons les entrepreneur.e.s d’afrique francophone dans le 0 to 1 pour les aider à mieux vendre, lever leur 1er fonds et trouver l’adéquation produit marché. N",
  },
  {
    id: 4,
    title: "une pédagogie basée sur la pratique",
    img: Icon4,
    text: "Nous accompagnons les entrepreneur.e.s d’afrique francophone dans le 0 to 1 pour les aider à mieux vendre, lever leur 1er fonds et trouver l’adéquation produit marché. N",
  },
];
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 6,
    itemsToScroll: 1,
  },
  tablet: {
    breakpoint: { max: 1599, min: 992 },
    items: 3,
    itemsToScroll: 1,
  },
  mobile: {
    breakpoint: { max: 991, min: 0 },
    items: 1,
    itemsToScroll: 1,
  },
};
const HomePage = () => {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content">
        <section className="section-banner-homepage py-5" id="banniere">
          <BannerPage />
        </section>
        <section className="section-aprops-homepage pb-5" id="a-propos">
          <div className="container-app-pitch-palabre">
            <div className="row row-a-propos pt-5 mt-5">
              <div className="col-md-6 col-left-a-propos pt-5">
                <div className="content-col-left-a-propos">
                  <div className="content-img-a-propos1">
                    <img src={Img1} alt="a propos" className="img-a-propos-1" />
                  </div>
                  <div className="content-img-a-propos2">
                    <img src={Img2} alt="a propos" className="img-a-propos-2" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-right-a-propos flex-r pt-5">
                <div className="content-col-right-a-propos">
                  <h2 className="h2-theme my-4">
                    À propos de <br />
                    Pitch Palabre
                  </h2>
                  <p className="text-a-propos">
                    Nous accompagnons les entrepreneur.e.s d’afrique francophone
                    dans le 0 to 1 pour les aider à mieux vendre, lever leur 1er
                    fonds et trouver l’adéquation produit marché. Nous concevons
                    implémentons des programmes d’accompagnement en Afrique
                    francophone. Nous créons des contenus en langue français à
                    destination des entrepreneurs et des chercheurs. Nous
                    formons les entrepreneurs selon une méthodologie unique.
                    Nous partageons nos expériences avec l’écosystème.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-aprops-homepage mt-5" id="approche">
          <div className="container-app-pitch-palabre position-relative pt-5">
            <div className="row row-approche pt-5 mt-5">
              <div className="col-md-6 col-left-approche">
                <div className="content-col-left-approche pt-5">
                  <h2 className="h2-theme my-4">Notre approche</h2>
                  <p className="text-a-propos">
                    Nous accompagnons les entrepreneur.e.s d’afrique francophone
                    dans le 0 to 1 pour les aider à mieux vendre, lever leur 1er
                    fonds et trouver l’adéquation produit marché. Nous concevons
                    implémentons des programmes d’accompagnement en Afrique
                    francophone. Nous créons des contenus en langue français à
                    destination des entrepreneurs et des chercheurs. Nous
                    formons les entrepreneurs selon une méthodologie unique.
                    Nous partageons nos expériences avec l’écosystème.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-right-approche">
                <div className="content-col-right-approche">
                  <div className="content-img-approche">
                    <img
                      src={ImgApproche}
                      alt="notre-approche"
                      className="img-approche-pitch-palabre"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-card-approche">
              {Datas.map((item) => (
                <div className="card-approche-item">
                  <div className="container-icon-approche pb-3">
                    <img
                      src={item.img}
                      alt={item.title}
                      className="icon-approche-card"
                    />
                  </div>
                  <div className="container-text-card-approche">
                    <h3 className="title-card-approche-item">{item.title}</h3>
                    <p className="text-card-approche-item mt-3">{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="conatiner-nos-valeur">
            <div className="container-app-pitch-palabre">
              <div className="content-section-nos-valeur">
                <div className="content-title-nos-valeur">
                  <h2 className="title-nos-valeur">
                    Nos <br />
                    valeurs
                  </h2>
                </div>
                <div className="content-info-nos-valeur">
                  <div className="info-nos-valeur-item">
                    <div className="content-icon-nos-valeur-item pb-2">
                      <img
                        src={IconValeur}
                        alt="icon valeur"
                        className="icon-valeur-pitch-palare"
                      />
                    </div>
                    <p className="text-nos-valeur-item mb-0">Audacieux</p>
                  </div>
                  <div className="info-nos-valeur-item">
                    <div className="content-icon-nos-valeur-item pb-2">
                      <img
                        src={IconValeur}
                        alt="icon valeur"
                        className="icon-valeur-pitch-palare"
                      />
                    </div>
                    <p className="text-nos-valeur-item mb-0">Engagé</p>
                  </div>
                  <div className="info-nos-valeur-item">
                    <div className="content-icon-nos-valeur-item pb-2">
                      <img
                        src={IconValeur}
                        alt="icon valeur"
                        className="icon-valeur-pitch-palare"
                      />
                    </div>
                    <p className="text-nos-valeur-item mb-0">Ouvert</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-aprops-homepage py-5" id="reference">
          <Reference />
        </section>
        <section className="section-aprops-homepage py-5 mb-5" id="equipe">
          <div className="container-app-pitch-palabre">
            <div className="content-title-section-reference text-center">
              <h2 className="h2-theme my-5">L’équipe</h2>
            </div>
            <div className="container-equipe">
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                keyBoardControl={false}
                arrows={false}
                customTransition="transform 1000ms ease-in-out"
                transitionDuration={2000}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {Team.map((item) => (
                  <div className="equipe-content-item" key={item.id}>
                    <div className="content-img-pp-equipe">
                      <img
                        src={item.image}
                        alt="équipe"
                        className="img-pp-equipe"
                      />
                    </div>
                    <p className="name-team mb-0">{item.name}</p>
                    <p className="fonction-team mb-0">{item.profession}</p>
                    <a className="btn btn-icon-team">
                      <FaLinkedin />
                    </a>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </section>
        <section className="nous-contacter-section py-5" id="nous-contacter">
          <div className="container">
            <div className="container-nous-contacter">
              <div className="content-title-section-nous-contacter text-center">
                <h2 className="h2-theme mb-5">Nous contacter</h2>
              </div>
              <div className="container-form">
                <form>
                  <div className="row row-nous-contacter">
                    <div className="col-md-6 col-nous-contacter">
                      <div className="content-inpt-form-contact">
                        <label className="form-label form-label-nous-contacter">
                          Votre nom
                        </label>
                        <input
                          type="text"
                          className="input-form-contact form-control"
                          placeholder="Votre nom"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-nous-contacter">
                      <div className="content-inpt-form-contact">
                        <label className="form-label form-label-nous-contacter">
                          Votre Prénom
                        </label>
                        <input
                          type="text"
                          className="input-form-contact form-control"
                          placeholder="Votre Prénom"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-nous-contacter">
                      <div className="content-inpt-form-contact">
                        <label className="form-label form-label-nous-contacter">
                          Votre message
                        </label>
                        <textarea
                          name="message"
                          id="message"
                          rows={6}
                          className="input-form-contact form-control"
                        >
                          Votre message
                        </textarea>
                      </div>
                    </div>
                    <div className="col-md-3 offset-md-9 col-form-contact mb-3 mt-4">
                      <div className="content-inpt-form-contact">
                        <button className="btn btn-connect-conctact w-100 trans-0-2">
                          Envoyer
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FrontFooter />
    </div>
  );
};

export default HomePage;

const Team = [
  {
    id: 1,
    image: EquipeImg,
    name: "Mafal LÔ ",
    profession: "CEO",
  },
  {
    id: 2,
    image: EquipeImg,
    name: "Raouti Cheti",
    profession: "COO",
  },
  {
    id: 3,
    image: EquipeImg,
    name: "Mayacine Diop",
    profession: "CPO",
  },
  {
    id: 4,
    image: EquipeImg,
    name: "Mandé Keita",
    profession: "CMO",
  },
  {
    id: 5,
    image: EquipeImg,
    name: "Momar Thiao",
    profession: "Legal Advisor & IP",
  },
  {
    id: 6,
    image: EquipeImg,
    name: "Moustapha Barro",
    profession: "COO",
  },
];
