import React from "react";
import "./FrontHeader.css";
import Logo from "../../../assets/appImages/logo.png";

const FrontHeader = () => {
  return (
    <div className="frontheader-component">
      <nav className="navbar navbar-expand-lg navbar-light headering nav-header-custom fixed-top">
        <div className="container-fluid header-container-fluid pb-0">
          <div className="logo-and-version-container">
            <div className="logo-container">
              <a className="navbar-brand content-logo-app" href="#banniere">
                <img src={Logo} alt="Pitch palabre" className="app-logo" />
              </a>
            </div>
          </div>
          <button
            className="navbar-toggler content-icon-menu-mobile"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse header-navbar-collapse"
            id="navbarNav"
          >
            <div className="w-100 header-items-container">
              <div className="d-flex w-100 header-navbar-contents-container align-items-center">
                <ul className="navbar-nav navbar-nav-header me-auto">
                  <li className="nav-item">
                    <a
                      className="nav-link menu-link"
                      aria-current="page"
                      href="#banniere"
                    >
                      ACCUEIL
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link menu-link" href="#a-propos">
                      À propos
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link menu-link" href="#approche">
                      Notre Approche
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link menu-link" href="#reference">
                      NOS RÉFÉRENCES
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link menu-link" href="#equipe">
                      L’ÉQUIPE
                    </a>
                  </li>
                </ul>
                <div className="bloc-flex-info-user-header-content"></div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default FrontHeader;
